import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

const CardWithImageHorizontal = (props) => {
  var onRight = props.onRight;

  if (window.innerWidth < 840) {
    onRight = true;
  }

  return (
    <MDBCard
      style={{ marginTop: "50px", marginBottom: "50px", padding: "20px" }}
    >
      <MDBRow className="g-0">
        {onRight && (
          <MDBCol md="4">
            <MDBCardImage src={props.imgSrc} alt="..." fluid />
          </MDBCol>
        )}

        <MDBCol md="8">
          <MDBCardBody>
            <MDBCardTitle>
              <h4>
                <bold>{props.title}</bold>
              </h4>
            </MDBCardTitle>
            <MDBCardText
              style={{ fontSize: window.innerWidth < 840 ? "12px" : null }}
            >
              {props.description}
            </MDBCardText>
            <MDBCardText>
              <small className="text-muted">{props.footer}</small>
            </MDBCardText>
          </MDBCardBody>
        </MDBCol>
        {!onRight && (
          <MDBCol md="4">
            <MDBCardImage
              src={props.imgSrc}
              alt="..."
              fluid
              style={{ borderRadius: 25 }}
            />
          </MDBCol>
        )}
      </MDBRow>
    </MDBCard>
  );
};

export default CardWithImageHorizontal;
