import React from "react";

const FooterSection = (props) => {
  return (
    <section
      style={{
        textAlign: "center",
        marginTop: "20px",
        backgroundColor: "black",
        height: "100px",
        paddingTop: "25px",
      }}
    >
      <h4 style={{ color: "gray", fontSize: "8px" }}>
        (c) 2022 BlazeWeb3 LLC.
      </h4>
    </section>
  );
};

export default FooterSection;
