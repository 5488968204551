import React from "react";
import HeaderSection from "./HomePage/HeaderSection";
import ProductInformationSection from "./HomePage/ProductInformationSection";
import SignupSection from "./HomePage/SignupSection";
import FooterSection from "./HomePage/FooterSection";
const sectionHeight = "1200px";

const MainBody = (props) => {
  return (
    <React.Fragment>
      <HeaderSection />
      <ProductInformationSection />
      <SignupSection />
      <FooterSection />
    </React.Fragment>
  );
};

export default MainBody;
