import React from "react";
import ProductDevTimeline from "../ProductTimelineSection/ProjectTimeline";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";

const ProductRoadmap = (props) => {
  return (
    <div
      id="productRoadmap"
      style={{ margin: window.innerWidth < 840 ? "5px" : "45px" }}
    >
      <MDBCard>
        <h1 style={{ textAlign: "center", marginTop: "20px" }}>
          Projected Product Roadmap
        </h1>
        <MDBCardBody>
          <ProductDevTimeline />
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};
export default ProductRoadmap;
