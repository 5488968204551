import React from "react";
import MailchimpFormContainer from "../../Universal/UI/MailchimpForm/MailchimpFormContainer";
import classes from "./SignupSection.module.css";
import logoImage from "../../../assets/logo_small.jpeg";
const sectionHeight = "400px";
const sectionTitle = (
  <span style={{ color: "white" }}>
    Lets <span style={{ textDecoration: "underline red" }}>Blaze</span> !
  </span>
);
const sectionDescription = "";

const SignupSection = (props) => {
  return (
    <section
      id="contact"
      style={{
        paddingTop: "25px",
        backgroundColor: "black",
      }}
    >
      <div class={classes.center}>
        <h1 style={{ textAlign: "center" }}>{sectionTitle}</h1>
      </div>
      <div class={classes.center}>
        <img
          src={logoImage}
          alt=""
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "25px",
            width: "100px",
            height: "100px",
          }}
        />
      </div>
      <div class={classes.center}>
        <h3 style={{ textAlign: "center", marginTop: "25px" }}>
          Early Adopters may qualify for Limited Level-1 Blaze' Tokens
        </h3>
      </div>
      <MailchimpFormContainer />
    </section>
  );
};

export default SignupSection;
