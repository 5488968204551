import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBRipple,
} from "mdb-react-ui-kit";
const backgroundStyle1 =
  "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)";
const backgroundStyle2 =
  "linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,215,0,0.7) 50%)";
const backgroundStyle3 =
  "linear-gradient(90deg,  rgba(255,215,0,0.7) 0%, rgba(255,255,255,0.9) 50%)";
const backgroundStyle4 =
  "linear-gradient(90deg, rgba(39,5,62,1) 0%, rgba(253,184,29,0.8001777563202247) 51%)";

const CardWithImage = (props) => {
  return (
    <MDBCard
      style={{
        minWidth: "250px",
        maxWidth: "350px",
        minHeight: "600px",
        margin: "10px",
      }}
    >
      <MDBCardBody>
        <MDBCardTitle>
          <bold>{props.title}</bold>
        </MDBCardTitle>
        <MDBRipple
          rippleColor="light"
          rippleTag="div"
          className="bg-image hover-overlay"
        >
          <MDBCardImage
            src={props.imgSrc}
            fluid
            alt="..."
            style={{ height: "300px", width: "100%", objectFit: "cover" }}
          />
          <a>
            <div
              className="mask"
              style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
            ></div>
          </a>
        </MDBRipple>
        <MDBCardText
          style={{
            marginTop: "20px",
            fontSize: window.innerWidth < 840 ? "12px" : null,
          }}
        >
          {props.description}
        </MDBCardText>
        {/* <MDBBtn href="#">Button</MDBBtn> */}
      </MDBCardBody>
    </MDBCard>
  );
};
export default CardWithImage;
