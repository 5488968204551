import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import { RocketLaunch } from "@mui/icons-material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import TimeLineComponent from "./TimeLineComponent";
import GroupsIcon from "@mui/icons-material/Groups";
import TokenIcon from "@mui/icons-material/Token";
const ProductDevTimeline = (props) => {
  return (
    <Timeline position="alternate">
      <TimeLineComponent
        date="Q2 2022"
        color="error"
        bgcolor="error.main"
        icon={<RocketLaunch />}
        title=" Marketplace Launch"
        subtitle="BlazeNFTs General Marketplace Goes Live with Fractional NFT Support"
      />
      <TimeLineComponent
        date="Q2 2022"
        color="primary"
        icon={<GroupsIcon />}
        title="Creators and Early Adopters Onboarding"
        subtitle=""
      />
      <TimeLineComponent
        date="Q2 2022"
        color="primary"
        icon={<TokenIcon />}
        title="Official Blaze NFT Tokens Available"
        subtitle="20k Level 1 Blaze Tokens Minted for Early Adopters and Creators"
      />

      <TimeLineComponent
        date="Q3 2022"
        color="primary"
        icon={<PhoneAndroidIcon />}
        title="BlazeNFTs Mobile App"
        subtitle="Cross-Platform Mobile App Launch"
      />

      <TimeLineComponent
        date="Q4 2022"
        color="secondary"
        icon={<AccountTreeIcon />}
        title="Multi-Blockchain Support"
        subtitle="Add support for multiple blockchains"
      />

      <TimeLineComponent
        date="Q4 2022"
        color="primary"
        icon={<LaptopMacIcon />}
        title="Investment Toolset Launch"
        subtitle="Artificial Intelligence Powered Tools to help you grow your
        portfolio"
      />

      <TimeLineComponent
        date="Beyond"
        color="error"
        icon={<LocalFireDepartmentIcon />}
        title="Integration with Real World Assets"
        subtitle={
          <p>
            {" "}
            Blaze NFT support in horizontal markets - Real Estate, Healthcare
            etc. <br />
            In-house Blockchain Support
            <br />
            Integration with Web3.0 Apps
            <br />
            ..and more!
          </p>
        }
      />
    </Timeline>
  );
};

export default ProductDevTimeline;
