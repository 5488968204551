import React from "react";
import classes from "./SharedCSS.module.css";

const TitleComponent = (props) => {
  return (
    <div class={classes.center}>
      <h1 style={{ textAlign: "center", color: "#f5f5f5" }}>{props.text}</h1>
    </div>
  );
};
export default TitleComponent;
