import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Navbar, Nav } from "react-bootstrap";
import logoImage from "../../assets/logo_small.jpeg";
import cssClasses from "./MainNavbar.module.css";
import MenuIcon from "@mui/icons-material/Menu";
const MainNavbar = (props) => {
  return (
    <div style={{ display: "block" }}>
      <Navbar bg="black" variant="dark" expand="sm" sticky="top">
        <Navbar.Brand href="#home">
          <div>
            <img
              src={logoImage}
              alt="BlazeNFTs Brand Logo"
              width="42"
              className={`align-top d-inline-block ${cssClasses.logoPadding}`}
              height="40"
            />
            <text className={cssClasses.logoTextAlignment}>
              Blaze NFT Platform
            </text>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <MenuIcon />
        </Navbar.Toggle>
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={`justify-content-end ${cssClasses.navPadding}`}
        >
          <Nav className="mr-auto">
            <Nav.Link href="#info">The Blaze Difference</Nav.Link>
            <Nav.Link href="#productRoadmap">Roadmap</Nav.Link>
            <Nav.Link href="#contact">Signup</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default MainNavbar;
