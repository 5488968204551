import React from "react";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import classes from "./ProductInformationSection.module.css";
import CardWithImage from "../../Universal/UI/Cards/CardWithImage";
import marketplaceImg from "../../../assets/marketplace.png";
import CardWithImageHorizontal from "../../Universal/UI/Cards/CardWithImageHorizontal";
import communityImage from "../../../assets/community3.png";
import poolingImg from "../../../assets/pooling4.png";
import exclusivityImg from "../../../assets/exclusive2.jpg";

const ForCreatorsSection = (props) => {
  return (
    <div style={{ margin: window.innerWidth < 840 ? "5px" : "45px" }}>
      <MDBCard>
        <h1 style={{ textAlign: "center", marginTop: "25px", color: "black" }}>
          For Creators
        </h1>
        <MDBCardBody>
          <div class={`container ${classes.center}`} style={{ marginTop: 25 }}>
            <div class="row">
              <div class="col">
                <h2 style={{ color: "black", marginLeft: "8px" }}>
                  Highly scalable solutions for creators and creatives
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="row">
                <CardWithImageHorizontal
                  title="Innovative Marketplace"
                  description="
                  Buy, Sell, Trade, Communicate, Collaborate, and Support NFTs on our Innovative new Marketplace Platform.
                  The BlazeNFT Marketplace is designed from the ground up to ensure that value is created and propogated throughout our community and beyond. 
                  
                  "
                  imgSrc={marketplaceImg}
                />
              </div>
              <div class="row">
                <CardWithImageHorizontal
                  title="Exclusivity and Rarity"
                  description="
                  Exclusive BlazeNFT Level-1 Tokens are used to secure and validate projects onto the BlazeNFT Marketplace.
                  This is done to ensure that NFT projects on the BlazeNFT Marketplace are authentic and verified to prevent value dilution.
                  Users may obtain Level-1 tokens after intial offering by community concensus. 
                  NFT projects submitted to the Blaze Marketplace are also vetted by our community via a voting, ranking, and rating system. 
                  "
                  imgSrc={exclusivityImg}
                  onRight={true}
                />
              </div>
              <div class="row">
                <CardWithImageHorizontal
                  title="Pooling and Fractional Sales"
                  description="
                  Your NFT projects can be bought or sold to a pool of Investors. The amount of fractional shares
                  of your project can be determined at time of creation/minting. You can also choose to buy or sell to individuals,
                  or a combonation of both approaches. 
                  This unique feature provides you with greater flexibility and control over the fate of your NFT project.
                  "
                  imgSrc={poolingImg}
                />
              </div>
            </div>
          </div>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};

export default ForCreatorsSection;
