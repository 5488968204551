import React from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";

const TimeLineComponent = (props) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: "auto 0" }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        {props.date}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector
          sx={{ bgcolor: props.bgcolor ? props.bgcolor : "" }}
        />
        <TimelineDot color={props.color}>{props.icon}</TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 2 }}>
        <Typography
          variant="h6"
          component="span"
          style={
            window.innerWidth < 840
              ? {
                  fontSize: "10px",
                }
              : {
                  fontSize: "20px",
                }
          }
        >
          {props.title}
        </Typography>
        <Typography
          style={
            window.innerWidth < 840
              ? {
                  fontSize: "8px",
                }
              : {
                  fontSize: "16px",
                }
          }
        >
          {props.subtitle}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimeLineComponent;

//  <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
