import React from "react";
import classes from "./HeaderSection.module.css";
import { MDBBtn } from "mdb-react-ui-kit";
import backgroundImage1 from "../../../assets/body_main_img.jpeg";
import backgroundImage2 from "../../../assets/industry.jpeg";
import backgroundImage3 from "../../../assets/chains.jpeg";
import backgroundImage4 from "../../../assets/world.jpeg";
import backgroundImage5 from "../../../assets/fire.jpeg";
import backgroundImage6 from "../../../assets/abstract1.jpg";
import backgroundImage7 from "../../../assets/abstract2.jpg";
import backgroundImage8 from "../../../assets/abstract3.jpg";
import CardWithImage from "../../Universal/UI/Cards/CardWithImage";

const backgroundStyle1 =
  "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)";
const backgroundStyle2 =
  "linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,215,0,0.7) 50%)";
const backgroundStyle3 =
  "linear-gradient(90deg,  rgba(255,215,0,0.7) 0%, rgba(255,255,255,0.9) 50%)";
const backgroundStyle4 =
  "linear-gradient(90deg, rgba(39,5,62,1) 0%, rgba(253,184,29,0.8001777563202247) 51%)";

const HeaderSection = (props) => {
  console.log(window.innerWidth);
  return (
    <section id="home" style={{ backgroundColor: "black", minHeight: "150px" }}>
      <div className={classes.headerImageCard}>
        <div
          className={`bg-image ${classes.container}`}
          style={{
            maxWidth: "100%",
            zIndex: "1",
            position: "relative",
            maxHeight: 1000,
          }}
        >
          <img
            src={window.innerWidth < 840 ? backgroundImage7 : backgroundImage6}
            className="w-100"
            alt="Background Img"
            height="100%"
          />
          <div className={`mask`}>
            <div
              class={`container ${classes["child-div"]}`}
              style={{ maxWidth: "100%", zIndex: "1", position: "relative" }}
            >
              <div
                class={`row ${classes.center}`}
                style={{
                  marginLeft: window.innerWidth < 840 ? 0 : 250,
                  marginRight: window.innerWidth < 840 ? 0 : 250,
                }}
              >
                <div class="row">
                  <h1
                    style={
                      window.innerWidth < 640
                        ? {
                            color: "white",
                            textAlign: "center",
                            fontSize: "18px",
                          }
                        : {
                            color: "white",
                            textAlign: "center",
                          }
                    }
                  >
                    Are{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                        color: window.innerWidth < 840 ? "white" : "gray",
                      }}
                    >
                      You
                    </span>{" "}
                    an aspiring Digital Creator worried your project will get
                    lost in an over-saturated NFT market?
                  </h1>
                  <h1
                    style={
                      window.innerWidth < 640
                        ? {
                            color: "white",
                            textAlign: "center",
                            fontSize: "16px",
                            fontStyle: "italic",
                          }
                        : {
                            color: "white",
                            textAlign: "center",
                            fontSize: "30px",
                            fontStyle: "italic",
                          }
                    }
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        color: window.innerWidth < 840 ? "white" : "gray",
                      }}
                    >
                      Or
                    </span>{" "}
                  </h1>

                  <h1
                    style={
                      window.innerWidth < 640
                        ? {
                            color: "white",
                            textAlign: "center",
                            fontSize: "18px",
                          }
                        : {
                            color: "white",
                            textAlign: "center",
                          }
                    }
                  >
                    Are you an Investor looking to looking to{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                        color: window.innerWidth < 840 ? "white" : "gray",
                      }}
                    >
                      Diversify
                    </span>{" "}
                    your investment portfolio without breaking the bank?
                  </h1>

                  <div
                    class={classes.center}
                    style={{ marginTop: window.innerWidth < 840 ? 10 : 45 }}
                  >
                    <MDBBtn
                      outline
                      color="light"
                      href="#contact"
                      style={{
                        color: window.innerWidth < 840 ? "white" : "white",
                      }}
                    >
                      Get Ready to Blaze!
                    </MDBBtn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderSection;
