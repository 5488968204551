import React from "react";
import "./App.css";
import MainBody from "./components/Body/Body";
import MainNavbar from "./components/Navigation/MainNavbar";

function App() {
  return (
    <React.Fragment>
      <MainNavbar />
      <MainBody />
    </React.Fragment>
  );
}

export default App;
