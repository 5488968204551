import React from "react";
import classes from "./ProductInformationSection.module.css";
import ForInvestorsSection from "./ForInvestorsSection";
import TitleComponent from "../../Universal/TextComponents/Title";
import SubtitleComponent from "../../Universal/TextComponents/Subtitle";
import ProductRoadmap from "../ProductTimelineSection/ProductRoadmap";
import ForCreatorsSection from "./ForCreatorsSection";
import logoImage from "../../../assets/logo_small.jpeg";

const sectionTitle = (
  <span>
    The <span style={{ textDecoration: "underline #FFD700" }}>Blazin'</span>{" "}
    Difference
  </span>
);

const ProductInformationSection = (props) => {
  return (
    <section
      id="info"
      style={{
        zIndex: "1",
        position: "relative",
        paddingTop: "45px",
        paddingBottom: "45px",
        backgroundColor: "black",
      }}
      class={classes.center}
    >
      <TitleComponent text={sectionTitle} />
      <SubtitleComponent
        horizontalMargin={window.innerWidth < 840 ? 25 : 250}
        logo={
          <div class={classes.center}>
            <img
              src={logoImage}
              alt=""
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100px",
                height: "100px",
              }}
            />
          </div>
        }
        text="BlazeNFTs an innovative NFT Marketplace and Crypto Investment Platform designed from the ground up. 
        Our core mission is to advance beyond the current market frenzy surrounding NFTs and provide you with a feature-rich platform which provides long-term innovation and value.
        "
      />
      <ForCreatorsSection />
      <ForInvestorsSection />
      <ProductRoadmap />
    </section>
  );
};

export default ProductInformationSection;
