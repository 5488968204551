import React from "react";
import classes from "./ProductInformationSection.module.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import fractionalImage from "../../../assets/fractional2.png";
import attainabilityImage from "../../../assets/tools.jpeg";
import toolsImage from "../../../assets/attainability2.jpeg";
import CardWithImage from "../../Universal/UI/Cards/CardWithImage";

const ForInvestorsSection = (props) => {
  return (
    <div
      style={{
        margin: window.innerWidth < 840 ? "5px" : "45px",
      }}
    >
      <MDBCard style={{ backgroundColor: "black" }}>
        <h1 style={{ textAlign: "center", marginTop: "25px", color: "white" }}>
          For Investors
        </h1>
        <MDBCardBody>
          <div class={`container ${classes.center}`} style={{ marginTop: 25 }}>
            <div class="row">
              <div class="col">
                <h2
                  style={{
                    color: "white",
                    marginLeft: "8px",
                    marginBottom: "25px",
                  }}
                >
                  Innovative toolsets for investors
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <CardWithImage
                  title="Attainablity"
                  description="
                  Investing into valuable NFT projects should be attainable for anyone.
                  With our unique pooling and fractionalizing approach, anyone can create a diverse investment portfolio of the hottest projects.
                  "
                  imgSrc={attainabilityImage}
                />
              </div>
              <div class="col">
                <CardWithImage
                  title="Advanced Analytics Toolkit"
                  description="
                  The BlazeNFT marketplace analytics toolset provides you with powerful tools to help you grow your portfolio.
                   Leverage our analytics and insights tools to help you gain insight on your portfolio performance.
                   "
                  imgSrc={toolsImage}
                  onRight={true}
                />
              </div>
              <div class="col">
                <CardWithImage
                  title="Fractional Investment"
                  description="Own fractional shares and create a diverse portfolio of valuable projects without breaking the bank."
                  imgSrc={fractionalImage}
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h2
                  style={{
                    color: "white",
                    marginLeft: "8px",
                    marginTop: "25px",
                  }}
                >
                  ... and so much more!
                </h2>
              </div>
            </div>
          </div>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};
export default ForInvestorsSection;
