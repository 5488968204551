import { autocompleteClasses } from "@mui/material";
import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const MailchimpFormContainer = (props) => {
  return (
    <div style={{ padding: "25px 0", textAlign: "center" }}>
      <iframe
        src="https://eepurl.com/hSMDR5"
        width="100%"
        height="800"
        title="mailchimp"
      ></iframe>
    </div>
  );
};

export default MailchimpFormContainer;
