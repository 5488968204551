import React from "react";
import classes from "./SharedCSS.module.css";
const SubtitleComponent = (props) => {
  return (
    <div class={classes.center}>
      <h3
        style={{
          textAlign: "center",
          color: "#f5f5f5",
          marginLeft: "25px",
          marginRight: "25px",
          marginTop: "25px",
          content: "Header",
        }}
      >
        {props.subheading}
      </h3>
      <hr
        style={{
          color: "white",
          marginLeft: props.horizontalMargin,
          marginRight: props.horizontalMargin,
        }}
      />
      {props.logo}

      <p
        style={{
          textAlign: "center",
          color: "#f5f5f5",
          marginLeft: props.horizontalMargin,
          marginRight: props.horizontalMargin,
          marginTop: "25px",
          marginBottom: "75px",
          fontSize: "18px",
        }}
      >
        {props.text}
      </p>
    </div>
  );
};
export default SubtitleComponent;
